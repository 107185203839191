
import PropTypes from "prop-types"
import React from "react"
import { Link } from "gatsby"
import logo from "./../images/logo.png"

const Header = ({ siteTitle }) => (
  <header>
    <div className="header-skew skew-right"></div>

    <div className="container">
      <div className="header">
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="Logo" />
            {
              // siteTitle
            }
          </Link>
        </div>

        <div className="site-nav">
          <ul className="nav justify-content-end">
            <li className="nav-item">
              <span className="nav-link">
                <Link to="/building-supplies/">Building Supplies</Link>
              </span>
            </li>
            <li className="nav-item">
              <span className="nav-link">
                <Link to="/development-consultancy/">Development Consultancy</Link>
              </span>
            </li>
            <li className="nav-item">
              <span className="nav-link">
                <Link to="/contact/">Contact us</Link>
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
