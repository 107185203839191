/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import { Link } from "gatsby"
// import logo from "./../images/logo.png"
// import "./layout.css"
// import("../scss/styles.scss")

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />

      <main className="site-main">
        {children}
      </main>

      <footer className="footer">
        <div className="container">
          <div className="footer-content">
            <ul className="footer-links">
              <li><Link to="/building-supplies/">Building Supplies</Link></li>
              <li><Link to="/development-consultancy/">Development Consultancy</Link></li>
              <li><Link to="/contact/">Contact us</Link></li>
            </ul>
            <div className="copyright">
            	© Copyright 2020 Grade A. All rights reserved.
            </div>

            <div className="footer-logo">
              <img src={`images/logo-01.png`} alt="Logo" />
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
